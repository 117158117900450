import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const createPricebook = async (companyId, locationId, stateCode) => {
  const response = await axios.post(
    `${API_BASE_URL}/api/createPricebook/${companyId}/${locationId}/${stateCode}`
  );

  return response.data;
};

export default {
  createPricebook,
};
