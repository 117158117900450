import React, { useState, useCallback, useEffect } from "react";

import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import logo from "../../../assets/images/whiteGasPosLogo.png";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { config } from "../../../Config";
import "./LoginForm.css";

const LoginForm = () => {
  const [loading, setLoading] = useState(false);

  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const navigate = useNavigate();

  const handleSignIn = async () => {
    setLoading(true);
    await instance.loginRedirect({
      scopes: config.scopes,
    });

    instance
      .handleRedirectPromise()
      .then((result) => {
        if (result && result.account) {
          navigate("/adminHome");
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <>
      <Box
        sx={{
          padding: {
            lg: 2,
          },
        }}
      >
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            padding: 1,
          }}
        >
          <Paper
            sx={{
              position: "relative",
              background: "linear-gradient(to right, #1c1d1e, #2a3734)",
              width: "500px",
              maxWidth: "100%",
              height: "275px",
              padding: 2,
            }}
          >
            <div
              style={{
                position: "absolute",

                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <Particles
                style={{ padding: "20px" }}
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                height="275px"
                options={{
                  fullScreen: { enable: false },
                  fpsLimit: 120,

                  particles: {
                    color: {
                      value: "#ffffff",
                    },
                    links: {
                      color: "#ffffff",
                      distance: 200,
                      enable: true,
                      opacity: 0.2,
                      width: 1,
                    },
                    collisions: {
                      enable: true,
                    },
                    move: {
                      directions: "none",
                      enable: true,
                      outModes: {
                        default: "bounce",
                      },
                      random: false,
                      speed: 2,
                      straight: false,
                    },
                    number: {
                      density: {
                        enable: true,
                        area: 1000,
                      },
                      value: 80,
                    },
                    opacity: {
                      value: 0.2,
                    },
                    shape: {
                      type: "circle",
                    },
                    size: {
                      value: { min: 1, max: 5 },
                    },
                  },
                  detectRetina: true,
                }}
              />
            </div>
            <Box marginTop={6}>
              <img src={logo} alt="Gas POS Logo" />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{ mt: 4, mb: 2 }}
                onClick={handleSignIn}
              >
                {loading ? "Loading..." : "Sign In"}
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default LoginForm;
