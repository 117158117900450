import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import axios from "axios";
import { useForm } from "react-hook-form";
import errorMessages from "../../../ErrorMessages";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PricebookTemplate from "./PricebookTemplate";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import "../styles/Form.scss";
import "../styles/Form.css";

const Form = ({ m, setIsSubmitted, setLoading }) => {
  const [pos, setPos] = useState("");
  const [entryExists, setEntryExists] = useState("");
  const [existingCustomer, setExistingCustomer] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || "Fallback";
  console.log(apiBaseUrl);

  const acceptedFileTypes = [
    "application/xaml+xml",
    "application/xhtml+xml",
    "text/xml",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
    "application/x-zip-compressed",
  ];

  const posData = [
    "Passport",
    "Ruby CI",
    "Ruby 2",
    "C18",
    "Ruby SuperSystem",
    "Ruby Supersystem II",
    "Modisoft",
    "Petrosoft",
  ];

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();

  const validateFiles = (files) => {
    const acceptedFiles = [];
    const acceptedFileNames = [];

    files.forEach((file) => {
      if (acceptedFileTypes.includes(file.type)) {
        acceptedFiles.push(file);
        acceptedFileNames.push(file.name);
      } else {
        alert(`Error: The file type of "${file.name}" is not accepted.`);
        window.location.reload();
      }
      setFiles([...acceptedFiles]);
      setFileNames([...acceptedFileNames]);
    });
  };

  const onFileChange = (event) => {
    const files = Array.from(event.target.files);
    validateFiles(files);
  };

  const handleChange = (event) => {
    setPos(event.target.value);
  };

  // const onSubmit = async (data) => {
  //   console.log(data);
  //   data.customerZipCode = data.customerZipCode.toString();
  //   setLoading(true);
  //   const formData = new FormData();
  //   files.forEach((f) => formData.append("file", f));

  //   console.log(formData);

  //   delete data.file;
  //   var companyId = data.companyId;
  //   var locationId = data.locationId;
  //   var zipCode = data.customerZipCode;
  //   console.log(
  //     `${apiBaseUrl}/api/pricebookupload/${companyId}/${locationId}/${zipCode}`
  //   );
  //   await fetch(
  //     `${apiBaseUrl}/api/pricebookupload/${companyId}/${locationId}/${zipCode}`,
  //     {
  //       method: "POST",
  //       body: formData,
  //     }
  //   ).then(async (x) => {
  //     if (!x.ok) {
  //       const errorMessage = await x.json();
  //       alert(errorMessage);
  //       return;
  //     }

  //     data.fileName = await x.json();
  //     console.log(data);

  //     if (!existingCustomer) {
  //       await fetch(`${apiBaseUrl}/api/submitApplication`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(data),
  //       }).then(async (x) => {
  //         if (!x.ok) {
  //           // notify the user to retry
  //           const errorMessage = await x.json();
  //           console.log(errorMessage);
  //           setEntryExists(errorMessage);
  //           return;
  //         }

  //         console.log(x);
  //         setLoading(false);
  //         setIsSubmitted(true);
  //       });
  //     } else {
  //       await fetch(`${apiBaseUrl}/api/updateApplication`, {
  //         method: "PUT",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(data),
  //       }).then(async (x) => {
  //         if (!x.ok) {
  //           // notify the user to retry
  //           const errorMessage = await x.json();
  //           console.log(errorMessage);
  //           setEntryExists(errorMessage);
  //           return;
  //         }

  //         console.log(x);
  //         setLoading(false);
  //         setIsSubmitted(true);
  //       });
  //     }
  //   });
  // };

  const onSubmit = async (data) => {
    console.log(data);
    data.customerZipCode = data.customerZipCode.toString();
    setLoading(true);
    const formData = new FormData();
    files.forEach((f) => formData.append("file", f));

    console.log(formData);

    delete data.file;
    var companyId = data.companyId;
    var locationId = data.locationId;
    var zipCode = data.customerZipCode;
    console.log(
      `${apiBaseUrl}/api/pricebookupload/${companyId}/${locationId}/${zipCode}`
    );

    try {
      // First POST request
      const response = await axios.post(
        `${apiBaseUrl}/api/pricebookupload/${companyId}/${locationId}/${zipCode}`,
        formData
      );
      data.fileName = response.data;
      console.log(data);

      if (!existingCustomer) {
        // Second POST request
        const submitResponse = await axios.post(
          `${apiBaseUrl}/api/submitApplication`,
          data,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        console.log(submitResponse);
      } else {
        // PUT request
        const updateResponse = await axios.put(
          `${apiBaseUrl}/api/updateApplication`,
          data,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        console.log(updateResponse);
      }

      setLoading(false);
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error during the request:", error);
      alert(error?.response?.data || "An error occurred");
      if (error?.response?.status === 400) {
        setEntryExists(error.response.data);
      }
      setLoading(false);
    }
  };

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: "easeOut" }}
    >
      <Paper elevation={5}>
        <Grid container className="form-cont">
          <Grid md={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <TextField
                    InputLabelProps={{
                      style: { color: "#2aaa91" },
                    }}
                    className="input-color"
                    {...register("businessName", {
                      required: true,
                    })}
                    id="standard-basic"
                    label="Business Name"
                    variant="outlined"
                    fullWidth={true}
                  />
                  {errors.businessName && (
                    <span className="error-message">
                      {errorMessages.businessNameRequired}
                    </span>
                  )}
                </Grid>
                <Grid xs={12}>
                  <TextField
                    InputLabelProps={{
                      style: { color: "#2aaa91" },
                    }}
                    {...register("companyId", {
                      valueAsNumber: true,
                      required: true,
                    })}
                    id="standard-basic"
                    label="Company ID"
                    variant="outlined"
                    fullWidth={true}
                  />
                  {errors.companyId && (
                    <span className="error-message">
                      {errorMessages.companyIdRequired}
                    </span>
                  )}
                </Grid>
                <Grid xs={12}>
                  <TextField
                    InputLabelProps={{
                      style: { color: "#2aaa91" },
                    }}
                    {...register("locationId", {
                      valueAsNumber: true,
                      required: true,
                    })}
                    id="standard-basic"
                    label="Location ID"
                    variant="outlined"
                    fullWidth={true}
                  />
                  {errors.locationId && (
                    <span className="error-message">
                      {errorMessages.locationIdRequired}
                    </span>
                  )}
                </Grid>
                <Grid xs={12}>
                  <TextField
                    InputLabelProps={{
                      style: { color: "#2aaa91" },
                    }}
                    {...register("customerName", {
                      required: true,
                    })}
                    id="standard-basic"
                    label="Customer Name"
                    variant="outlined"
                    fullWidth={true}
                  />
                  {errors.customerName && (
                    <span className="error-message">
                      {errorMessages.customerNameRequired}
                    </span>
                  )}
                </Grid>
                <Grid xs={12}>
                  <TextField
                    InputLabelProps={{
                      style: { color: "#2aaa91" },
                    }}
                    {...register("customerEmail", {
                      required: true,
                      pattern: /^\S+@\S+$/i,
                    })}
                    id="standard-basic"
                    label="Customer Email"
                    variant="outlined"
                    fullWidth={true}
                  />
                  {errors.customerEmail && (
                    <span className="error-message">
                      {errorMessages.customerEmailInvalid}
                    </span>
                  )}
                  {entryExists === "Email already exists" && (
                    <span className="error-message">{entryExists}</span>
                  )}
                </Grid>
                <Grid xs={12}>
                  <TextField
                    InputLabelProps={{
                      style: { color: "#2aaa91" },
                    }}
                    {...register("customerZipCode", {
                      required: true,
                    })}
                    id="standard-basic"
                    label="Zip Code"
                    variant="outlined"
                    fullWidth={true}
                  />
                  {errors.customerZipCode && (
                    <span className="error-message">
                      {errorMessages.customerZipCodeRequired}
                    </span>
                  )}
                </Grid>

                <Grid xs={12}>
                  <FormControl fullWidth>
                    <InputLabel
                      sx={{ color: "#2aaa91" }}
                      id="demo-simple-select-label"
                    >
                      POS
                    </InputLabel>
                    <Select
                      {...register("pos", {
                        required: true,
                      })}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={pos}
                      label="Pos"
                      onChange={handleChange}
                    >
                      {posData.map((pos, i) => (
                        <MenuItem key={i} value={pos}>
                          {pos}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors.pos && (
                    <span className="error-message">
                      {errorMessages.posRequired}
                    </span>
                  )}
                </Grid>

                <Grid
                  sx={{
                    marginLeft: 1,
                    marginTop: 1.5,
                  }}
                  xs={6}
                  md={6}
                  lg={4}
                >
                  <p style={{ color: "#66a892" }}>Price Book Files:</p>
                </Grid>

                <Grid
                  sx={{
                    marginTop: 1,
                  }}
                  xs={6}
                  md={6}
                  lg={6}
                >
                  <Button
                    className="small-button"
                    variant="contained"
                    component="label"
                    htmlFor="file-input"
                  >
                    Upload File
                    <input
                      {...register("file", {
                        required: true,
                      })}
                      onChange={onFileChange}
                      multiple
                      type="file"
                      id="file-input" // Add an id attribute here
                    />
                  </Button>
                </Grid>
                {errors.file && (
                  <span className="error-message">
                    {errorMessages.fileRequired}
                  </span>
                )}
              </Grid>
              <Grid
                sx={{
                  marginTop: 1,
                }}
                xs={6}
                md={6}
                lg={6}
              >
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      checked={existingCustomer}
                      onChange={(e) => setExistingCustomer(e.target.checked)}
                    />
                  }
                  label="Updating an exisiting pricebook"
                />
              </Grid>
              <Grid xs={12} fullWidth={true}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                  }}
                >
                  Send
                </Button>
              </Grid>
              {fileNames.length > 0 && (
                <ul>
                  {fileNames.map((fileName, index) => (
                    <li style={{ color: "#95ac9f" }} key={index}>
                      {fileName}
                    </li>
                  ))}
                </ul>
              )}
            </form>
          </Grid>
          <Grid xs={12} md={6} lg={6}>
            <PricebookTemplate templateName="File Templates" />
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            xs={6}
          ></Grid>
        </Grid>
      </Paper>
    </m.div>
  );
};

export default Form;
