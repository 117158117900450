import React from "react";
import logo from "../../../assets/images/whiteGasPosIconLogo.png";
import Box from "@mui/material/Box";

const TopBar = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "75px",
          display: "flex",
          alignItems: "center",
        }}
        className="top-navbar"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          <img
            style={{
              maxWidth: "50px",
              height: "auto",
            }}
            src={logo}
            alt="Gas POS Logo"
          />
        </Box>
      </Box>
    </>
  );
};

export default TopBar;
