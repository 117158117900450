import React, { useState, useCallback, useEffect } from "react";
import LoginForm from "../../components/Admin/Login/LoginForm";
import TopBar from "../../components/Admin/TopBar/TopBar";
import { motion as m } from "framer-motion";
import "./Login.css";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const Login = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/adminHome");
    }
  }, [isAuthenticated, navigate]);
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <TopBar />
      <LoginForm />
    </m.div>
  );
};

export default Login;
