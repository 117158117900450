import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Replace with your actual API base URL

const downloadBlob = async (blobId) => {
  const response = await axios.get(`${API_BASE_URL}/api/download/${blobId}`, {
    responseType: "blob", // Set response type to 'blob'
  });

  return response.data;
};

export default {
  downloadBlob,
};
