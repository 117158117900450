import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Form from "../../components/Home/Form/component/Form";
import Fab from "@mui/material/Fab";
import Confirmation from "../../components/Home/Form/component/Confirmation";
import PhoneIcon from "@mui/icons-material/LocalPhone";
import Typography from "@mui/material/Typography";
import Loading from "../../components/Home/Form/component/Loading";
import "../../styles/App.scss";
import "../../styles/App.css";
import logo from "../../assets/images/gasPosLogo.png";

import { motion as m } from "framer-motion"; //"framer-motion/dist/framer-motion"
const Home = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <div className="bg-image">
        <div>
          <Grid container direction="row" className="content">
            <Grid item md={6} xs={12} className="text-col">
              <img
                className="logo-shadow"
                style={{
                  maxWidth: "85%",
                  height: "auto",
                }}
                src={logo}
                alt="Gas POS Logo"
              />
              <h3 className="header-shadow">
                Gas Pos is the fastest-growing cloud-based pos system for gas
                stations & truck stops across USA.
              </h3>

              <a target="_blank" href="https://www.gaspos.co/">
                <Button variant="contained">Learn More</Button>
              </a>
            </Grid>
            <Grid item md={6} xs={12} className="form-col">
              {isSubmitted ? (
                <Confirmation m={m} />
              ) : loading ? (
                <Loading m={m} />
              ) : (
                <Form
                  m={m}
                  setLoading={setLoading}
                  setIsSubmitted={setIsSubmitted}
                />
              )}
            </Grid>

            <a href="tel:18667427767">
              <Fab
                sx={{
                  position: "absolute",
                  bottom: {
                    lg: "2%",
                    xs: "-10%",
                  },
                  right: {
                    lg: "2%",
                    xs: "2%",
                  },
                }}
                variant="extended"
              >
                <PhoneIcon
                  sx={{
                    marginRight: {
                      lg: 1,
                    },
                    color: "#2aaa91",
                  }}
                />

                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#2aaa91",
                  }}
                  variant="button"
                  className="contact-support-button"
                >
                  Contact Support
                </Typography>
              </Fab>
            </a>
          </Grid>
        </div>
      </div>
    </m.div>
  );
};

export default Home;
