import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import { Folder } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2";
import pbTemplate from "../../../../pbTemplate.csv";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  color: "#F8F8F8",
  fontSize: "18px",
  width: "100%",
  height: "50px",

  "&:hover": {
    backgroundColor: "white",
    color: "#8aad9e",
    border: "1px solid #8aad9e",
  },
};
const PricebookTemplate = ({ templateName, templateInfo }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const xmlInfo = ["FGM", "ISM", "MCM", "MSM", "PRJ", "SEM", "TLM", "TSM"];

  return (
    <div>
      {/* <a onClick={handleOpen}>
        <Button sx={buttonStyle} variant="contained" component="label">
          {templateName}
        </Button>
      </a> */}

      <a href={pbTemplate} download="pbTemplate" target="_blank">
        <Button sx={buttonStyle} variant="contained" component="label">
          Download CSV Template
        </Button>
      </a>

      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            NAXML Files Must Include:
          </Typography>
          <br />

          {xmlInfo.map((file, i) => {
            return (
              <Grid padding={0.5} key={i} id="modal" container>
                <Grid xs={2}>
                  <FolderIcon />
                </Grid>
                <Grid xs={10}>
                  <Typography>{file}</Typography>
                </Grid>
              </Grid>
            );
          })}

          <br />
          <a href={pbTemplate} download="pbTemplate" target="_blank">
            <Button sx={buttonStyle} variant="contained" component="label">
              Download CSV Template
            </Button>
          </a>
        </Box>
      </Modal> */}
    </div>
  );
};

export default PricebookTemplate;
