import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./styles/App.css";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { motion as m } from "framer-motion";
import { MsalProvider } from "@azure/msal-react";

import AnimatedRoutes from "./routes/AnimatedRoutes";

function App({ msalInstance }) {
  const theme = createTheme({
    typography: {
      fontFamily: `"Poppins"`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    palette: {
      primary: {
        main: "#353935",
        contrastText: "#F8F8F8",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: `"Poppins"`,
            backgroundColor: "#353935",
            color: "#F8F8F8",
            fontSize: "18px",
            width: "100%",
            height: "50px",
            border: "1px solid transparent", // Add this line
            "&:hover": {
              backgroundColor: "white",
              color: "#8aad9e",
              border: "1px solid #8aad9e",
            },
          },
        },
      },
    },
  });

  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Router>
            <AnimatedRoutes />
          </Router>
        </CssBaseline>
      </ThemeProvider>
    </MsalProvider>
  );
}

export default App;
