import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { motion as m } from "framer-motion";

import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import Admin from "../pages/Admin/Admin.jsx";

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/index.html" element={<Home />} />
        <Route exact path="/adminLogin" element={<Login />} />
        <Route exact path="/adminHome" element={<Admin />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
