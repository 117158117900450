import React, { useEffect } from "react";
import Sidebar from "../../components/Admin/Sidebar/Sidebar";
import CustomerDataTable from "../../components/Admin/DashboardHeader/CustomerDataTable";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { motion as m } from "framer-motion";
import "./Admin.css";

const Admin = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (inProgress === "none" && !isAuthenticated) {
      navigate("/adminLogin");
    }
  }, [isAuthenticated, inProgress, navigate]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <Sidebar />
      <div className="admin-container">
        <CustomerDataTable />
      </div>
    </m.div>
  );
};

export default Admin;
