const errorMessages = {
  emailRequired: "Email Required",
  passwordRequired: "Password Required",
  businessNameRequired: "Business Name is required",
  siteNameRequired: "Value must be a digit",
  customerNameRequired: "Customer Name is required",
  customerEmailRequired: "Customer Email is required",
  customerEmailInvalid: "Please enter a valid email address",
  posRequired: "Please select a POS",
  fileRequired: "Please upload at least one file",
  customerZipCodeRequired: "Zip Code is required",
  companyIdRequired: "Company ID is Required",
  locationIdRequired: "Location ID is Required",
};

export default errorMessages;
