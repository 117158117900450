import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import "../styles/Form.scss";
import "../styles/Form.css";
import Skeleton from "@mui/material/Skeleton";

const Loading = ({ m }) => {
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: "easeOut" }}
    >
      <Paper elevation={5}>
        <Grid container className="form-cont">
          <Grid md={12}>
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Skeleton variant="rounded" animation="wave" height={56} />
              </Grid>
              <Grid xs={12}>
                <Skeleton variant="rounded" animation="wave" height={56} />
              </Grid>
              <Grid xs={12}>
                <Skeleton variant="rounded" animation="wave" height={56} />
              </Grid>
              <Grid xs={12}>
                <Skeleton variant="rounded" animation="wave" height={56} />
              </Grid>

              <Grid xs={12}>
                <Skeleton variant="rounded" animation="wave" height={56} />
              </Grid>

              <Grid xs={12} md={12} lg={4}>
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  height={36.5}
                  width={113}
                />
              </Grid>

              <Grid xs={12} md={6} lg={6}>
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  height={36.5}
                  width={113}
                />
              </Grid>

              <Grid xs={12} md={12} lg={12}>
                <Skeleton variant="rounded" animation="wave" height={56} />
              </Grid>
              <Grid xs={6}>
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  height={36.5}
                  width={113}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </m.div>
  );
};

export default Loading;
