import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Replace with your actual API base URL

const getAllCustomers = async (pageNumber, pageSize, search) => {
  const response = await axios.get(
    `${API_BASE_URL}/customerInfo?PageNumber=${pageNumber}&PageSize=${pageSize}&Search=${search}`
  );

  return response.data;
};

const rejectCustomer = async (customerId, customerEmail, message) => {
  const data = {
    customerId,
    customerEmail,
    message,
  };

  const response = await axios.post(`${API_BASE_URL}/api/rejectCustomer`, data);

  return response.data;
};

export default {
  getAllCustomers,
  rejectCustomer,
};
