import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io5";

const SidebarMenuData = [
  {
    title: "Home",
    path: "/",
    icon: <AiIcons.AiFillHome />,
    cName: "nav-text",
  },
  // {
  //   title: "Log Out",
  //   path: "/logout",
  //   icon: <IoIcons.IoLogOutOutline />,
  //   cName: "nav-text",
  // },
];

export default SidebarMenuData;
