import React, { useState, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "../styles/Form.scss";
import "../styles/Form.css";
import Paper from "@mui/material/Paper";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";

const Confirmation = ({ m }) => {
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: "easeIn" }}
    >
      <Paper elevation={5}>
        <Grid container className="form-cont">
          <Grid
            sx={{
              marginTop: "auto",
              marginBottom: "auto",
            }}
            xs={2}
          >
            <CheckIcon
              sx={{
                color: "#353935",
                fontSize: "3rem",
              }}
            />
          </Grid>
          <Grid xs={10}>
            <Typography marginLeft={3} paragraph={true} variant="body2">
              Thanks for uploading your files!
            </Typography>
            <br />
            <Typography marginLeft={3} paragraph={true} variant="body2">
              Please Check your email for confirmation.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </m.div>
  );
};

export default Confirmation;
