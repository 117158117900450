import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Hamburger from "hamburger-react";
import SidebarMenuData from "../../../constants/SidebarMenuData";
import "./styles.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import logo from "../../../assets/images/whiteGasPosIconLogo.png";
import * as IoIcons from "react-icons/io5";
import { useMsal } from "@azure/msal-react";

function Sidebar() {
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState("");

  const toggleDrawer = (isOpen) => {
    setOpen(isOpen);
  };

  const { instance } = useMsal();

  useEffect(() => {
    const currentAccount = instance.getActiveAccount();
    if (currentAccount) {
      setUsername(
        currentAccount.name.substring(0, currentAccount.name.indexOf(" "))
      );
    }
  });

  const handleLogOut = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/adminLogin",
    });
  };

  const list = () => (
    <List
      sx={{
        marginTop: 10,
      }}
    >
      <ListItem>
        <ListItemText
          className="sidebar-list-item-icon"
          primary={`Welcome, ${username}`}
        />
      </ListItem>
      {SidebarMenuData.map((item, index) => (
        <ListItem
          button
          key={index}
          component={Link}
          to={item.path}
          onClick={() => toggleDrawer(false)}
        >
          <ListItemIcon className="sidebar-list-item-icon">
            {item.icon}
          </ListItemIcon>
          <ListItemText
            className="sidebar-list-item-icon"
            primary={item.title}
          />
        </ListItem>
      ))}
      <ListItem button onClick={handleLogOut}>
        <ListItemIcon className="sidebar-list-item-icon">
          <IoIcons.IoLogOutOutline />
        </ListItemIcon>
        <ListItemText className="sidebar-list-item-icon" primary="Log Out" />
      </ListItem>
    </List>
  );

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "75px",
          display: "flex",
        }}
        className={`hamburger-icon top-navbar ${
          open ? "hamburger-icon-open" : ""
        }`}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "1rem",
          }}
          onClick={() => toggleDrawer(!open)}
        >
          <Hamburger color="#fbfaf5" toggled={open} toggle={setOpen} />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
            paddingRight: "1rem",
          }}
        >
          <img
            style={{
              maxWidth: "50px",
              height: "auto",
            }}
            src={logo}
            alt="Gas POS Logo"
          />
        </Box>
      </Box>
      <Drawer
        anchor="left"
        open={open}
        onClose={() => toggleDrawer(false)}
        PaperProps={{
          style: {
            position: "fixed",
            zIndex: 1400,
            width: "300px",
            background: "linear-gradient(to right, #1c1d1e, #2a3734)",
          },
        }}
      >
        {list()}
      </Drawer>
    </>
  );
}

export default Sidebar;
