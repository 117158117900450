import React, { useState, useEffect } from "react";
// import axios from "axios";
import Button from "@mui/material/Button";
import styled from "styled-components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import Unread from "@mui/icons-material/FiberManualRecord";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import customerService from "../../../services/CustomerInformationService";
import downloadService from "../../../services/DownloadService";
import RejectModal from "./RejectModal";
import CreatePricebook from "./CreatePricebook";
import { motion as m } from "framer-motion";

const Container = styled.div`
  align-items: center;

  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 25px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 25px;
`;

const SearchInput = styled(TextField)`
  margin-right: 8px;
`;

const RowChangeContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 16px;
  padding: 25px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

const FixedSizeButtonContainer = styled.div`
  display: flex;

  width: 300px;
`;

const CreatePricebookContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 16px;
  padding: 25px;
`;

const CustomerDataTable = () => {
  const [allCustomers, setAllCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [search, setSearch] = useState("");

  const handleRowsChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setCurrentPage(0);
  // };

  useEffect(() => {
    const fetchData = async () => {
      const customers = await customerService.getAllCustomers(
        currentPage,
        rowsPerPage,
        search
      );
      console.log(customers.customerInformation[0].totalRows);
      setTotalCustomers(customers.customerInformation[0].totalRows);
      const sortedData = customers.customerInformation.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      console.log(sortedData);
      setAllCustomers(sortedData);
    };

    fetchData();
  }, [currentPage, search, rowsPerPage]);

  const downloadPricebook = async (blobId) => {
    const data = await downloadService.downloadBlob(blobId);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${blobId}.zip`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Update the customers state after successful download
    setAllCustomers((prevCustomers) =>
      prevCustomers.map((customer) => {
        return {
          ...customer,
          unread: customer.pricebook.some(
            (pricebook) => pricebook.blobStorageId === blobId
          )
            ? false
            : customer.unread,
        };
      })
    );
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  return (
    <Container>
      <m.div
        key={`${currentPage}-${rowsPerPage}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        <TableContainer component={Paper}>
          <HeaderContainer>
            <Typography variant="h6">Customer Information</Typography>

            <SearchInput
              onChange={(e) => {
                handleSearch(e);
              }}
              label={<SearchIcon />}
            />
          </HeaderContainer>
          <CreatePricebookContainer>
            <CreatePricebook />
          </CreatePricebookContainer>
          <RowChangeContainer>
            <FormControl sx={{ width: "125px" }}>
              <InputLabel id="demo-simple-select-label">
                Rows per page:{" "}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                label="Rows per page"
                onChange={handleRowsChange}
              >
                {Array.from({ length: 20 }, (_, i) => (i + 1) * 5).map(
                  (value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </RowChangeContainer>
          <Table aria-label="responsive data table">
            <TableHead>
              <TableRow>
                <TableCell>Business Name</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>POS</TableCell>
                <TableCell>Files</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allCustomers.map((customer) => (
                <TableRow key={customer.id}>
                  <TableCell>
                    <Box sx={{ display: "flex" }}>
                      {customer.unread ? (
                        <Unread
                          sx={{
                            color: "#de3c4b",
                            marginRight: 1,
                            fontSize: "0.6rem",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        />
                      ) : (
                        ""
                      )}

                      {customer.businessName}
                    </Box>
                  </TableCell>
                  <TableCell>{customer.customerName}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>{customer.pos}</TableCell>
                  {customer.pricebook.map((pricebook, i) => (
                    <TableCell sx={{ width: "300px" }} key={customer.id}>
                      <Box sx={{ display: "flex" }}>
                        <FixedSizeButtonContainer>
                          <Button
                            onClick={() =>
                              downloadPricebook(pricebook.blobStorageId)
                            }
                            variant="contained"
                            sx={{ width: "150px" }} // Adjust this value based on your desired button width
                          >
                            Download
                          </Button>
                          <RejectModal
                            customerEmail={customer.email}
                            customerId={customer.id}
                            sx={{ width: "150px" }} // Adjust this value based on your desired button width
                          />
                        </FixedSizeButtonContainer>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </m.div>
      <PaginationContainer>
        <Pagination
          count={Math.ceil(totalCustomers / rowsPerPage)}
          onChange={handleChangePage}
          color="primary"
          size="small"
        />
      </PaginationContainer>
    </Container>
  );
};

export default CustomerDataTable;
