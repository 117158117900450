import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import createPricebookService from "../../../services/CreatePricebook";
import { useForm } from "react-hook-form";
import stateData from "../../../constants/StateData";
import { create } from "@mui/material/styles/createTransitions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CreatePricebook = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [open, setOpen] = useState(false);
  const [selectedState, setSelectedState] = useState(stateData[0].value);

  const handleChange = (event) => {
    setSelectedState(event.target.value);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = async (data) => {
    const response = await createPricebookService.createPricebook(
      data.companyId,
      data.locationId,
      data.stateCode
    );

    console.log({ response });
  };
  return (
    <>
      <Button onClick={handleOpen} sx={{ width: "200px" }} fullWidth="false">
        Create Pricebook
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component="form" sx={style} onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: "flex" }}>
            <TextField
              {...register("companyId", {
                required: true,
              })}
              sx={{ marginLeft: 2, marginBottom: 1 }}
              id="outlined-multiline-static"
              label="Company ID"
            />
          </Box>
          <Box sx={{ marginLeft: 2 }}>
            {errors.companyId && (
              <span style={{ color: "red" }}>Please enter a Company ID</span>
            )}
          </Box>
          <Box sx={{ display: "flex", marginBottom: 1 }}>
            <TextField
              {...register("locationId", {
                required: true,
              })}
              sx={{ marginLeft: 2 }}
              id="outlined-multiline-static"
              label="Location ID"
            />
          </Box>
          <Box sx={{ marginLeft: 2 }}>
            {errors.locationId && (
              <span style={{ color: "red" }}>Please enter a Location ID</span>
            )}
          </Box>
          <Box sx={{ display: "flex" }}>
            <Select
              sx={{ marginLeft: 2, width: "223.5px" }}
              labelId="state-select-label"
              id="state-select"
              {...register("stateCode", {
                required: true,
              })}
              value={selectedState}
              label="State"
              onChange={handleChange}
            >
              {stateData.map((state) => (
                <MenuItem key={state.value} value={state.value}>
                  {state.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {errors.stateCode && <span>Please enter a state</span>}
          <Box sx={{ marginTop: 2 }}>
            {" "}
            <Button type="submit">Create</Button>{" "}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CreatePricebook;
