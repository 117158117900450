import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import customerService from "../../../services/CustomerInformationService";
import { useForm } from "react-hook-form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const RejectModal = ({ customerId, customerEmail, sx }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = async (data) => {
    const response = await customerService.rejectCustomer(
      customerId,
      customerEmail,
      data.message
    );
    console.log(response);
  };

  return (
    <>
      <Button
        sx={{
          backgroundColor: "#de3c4b",
          marginLeft: 1,
          ...sx, // Spread the sx prop to apply the fixed width
        }}
        onClick={handleOpen}
      >
        Reject
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component="form" sx={style} onSubmit={handleSubmit(onSubmit)}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Rejection cause:
          </Typography>
          <TextField
            {...register("message", {
              required: true,
            })}
            sx={{
              width: "100%",
              marginTop: 2,
            }}
            id="outlined-multiline-static"
            label="Message"
            multiline
            rows={4}
          />
          {errors.message && <span>Please enter a message</span>}

          <Button type="submit" sx={{ marginTop: 2 }}>
            Send
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default RejectModal;
